<template>
  <span>{{ ago }}</span>
</template>
<script>
import moment from "moment";

export default {
  name: "FromNow",
  props: {
    date: String
  },
  data() {
    return {
      ago: ""
    };
  },
  computed: {},
  created() {
    const me = this;
    me.updateAgoString();
    setInterval(() => {
      me.updateAgoString();
    }, 5000);
  },
  methods: {
    updateAgoString() {
      const me = this;
      moment.locale("fi");
      me.ago = moment.utc(me.date).fromNow();
    }
  }
};
</script>
