<template>
  <base-dialog
    v-model="dialog"
    title="Uusi tilatieto"
    icon="mdi-plus"
    :valid-form="validForm"
    :is-loading="isLoading"
    @cancel="handleCancel"
    @save="createStatus"
  >
    <template #content>
      <v-form v-model="validForm">
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="description"
              label="Nimi"
              filled
              hint='Esimerkki: "Matkalla"'
              required
              :rules="$rules.required"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              label="Numeerinen arvo"
              hint='Käytä VIRVE-verkon status-arvoa, mikäli mahdollista. Esimerkiksi "327001"'
              v-model.number="value"
              filled
              required
              :rules="$rules.number"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            Taustaväri
          </v-col>
          <v-col cols="12" class="ml-3">
            <v-color-picker
              v-model="colorBackground"
              flat
              show-swatches
              hide-canvas
              hide-inputs
              hide-mode-switch
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            Tekstin väri
          </v-col>
          <v-col cols="12" class="ml-3">
            <v-color-picker
              v-model="colorText"
              flat
              show-swatches
              hide-canvas
              hide-inputs
              hide-mode-switch
            />
          </v-col>
        </v-row>
      </v-form>
    </template>
  </base-dialog>

  <v-btn color="white" @click="newStatus">
    <v-icon size="small">mdi-plus</v-icon>
    Uusi tilatieto
  </v-btn>
</template>
<style lang="sass" scoped></style>
<script>
import BaseDialog from "./BaseDialog.vue";
import FormMixin from "@/mixins/FormMixin";

export default {
  components: { BaseDialog },
  mixins: [FormMixin],
  props: {
    inModal: Boolean
  },
  data: () => ({
    validForm: false,
    dialog: false
  }),
  computed: {
    id: {
      get() {
        return this.$store.state.status.editor.id;
      },
      set(value) {
        this.$store.commit("status/set", ["editor.id", value]);
      }
    },
    value: {
      get() {
        return this.$store.state.status.editor.value;
      },
      set(value) {
        this.$store.commit("status/set", ["editor.value", value]);
      }
    },
    description: {
      get() {
        return this.$store.state.status.editor.name;
      },
      set(value) {
        this.$store.commit("status/set", ["editor.name", value]);
      }
    },
    colorText: {
      get() {
        return this.$store.state.status.editor.colorText;
      },
      set(value) {
        this.$store.commit("status/set", ["editor.colorText", value]);
      }
    },
    colorBackground: {
      get() {
        return this.$store.state.status.editor.colorBackground;
      },
      set(value) {
        this.$store.commit("status/set", ["editor.colorBackground", value]);
      }
    }
  },
  watch: {
    validForm(value) {
      this.$emit("is-valid", value);
    }
  },
  methods: {
    async newStatus() {
      const me = this;
      await me.$store.commit("status/newStatusToEditor", me.$store.state);
      me.dialog = true;
    },
    async createStatus() {
      const me = this;
      try {
        const status = await me.$store.dispatch("status/createStatus");
        me.$toast({
          type: "success",
          message: `Uusi tilatieto '${status.name}' on tallennettu`
        });
        me.dialog = false;
      } catch (err) {
        if (err.message !== "cancel") {
          me.$error(err);
        }
      }
    },
    handleCancel() {
      this.dialog = false;
    }
  }
};
</script>
