<template>
  <ol-map style="height: calc(100vh - 64px);">
    <ol-view :center="center" :zoom="12" :projection="projection" ref="view" @change:center="centerChanged" />
    <ol-tile-layer>
      <ol-source-osm />
    </ol-tile-layer>

    <ol-context-menu-control :items="contextMenuItems" :defaultItems="false" :width="170" />

    <ol-vector-layer>
      <ol-source-vector>
        <ol-feature v-for="(unitWithPos, index) in unitsWithPositions" :key="index"
          :properties="{ unitId: unitWithPos.id }">
          <ol-geom-point
            :coordinates="fromLonLat([unitWithPos.latestPosition.longitude, unitWithPos.latestPosition.latitude])"></ol-geom-point>
          <ol-style>
            <ol-style-circle :radius="10">
              <ol-style-fill :color="unitWithPos.status?.colorBackground"></ol-style-fill>
              <ol-style-stroke color="#000000" :width="2"></ol-style-stroke>
            </ol-style-circle>
            <ol-style-text :text="unitWithPos.name" :offset-y="-20" font="bold 14px Arial" fill-color="black"
              stroke-color="white" stroke-width="2" />
          </ol-style>
        </ol-feature>
        <ol-interaction-modify @modifyend="onFeatureMoved" />
      </ol-source-vector>
    </ol-vector-layer>

    <ol-vector-layer>
      <ol-source-vector>
        <ol-feature v-for="(mission, index) in missionsWithPositions" :key="index"
          :properties="{ missionsId: missions.id }">
          <ol-geom-point :coordinates="fromLonLat([mission.longitude, mission.latitude])"></ol-geom-point>
          <ol-style>
            <ol-style-icon :src="markerIcon" :scale="0.05"></ol-style-icon>
            <ol-style-text :text="missions.code" :offset-y="-20" font="bold 14px Arial" fill-color="black"
              stroke-color="white" stroke-width="2" />
            <ol-style-text :text="'Tehtävä ' + mission.code + ' - ' + mission.address" :offset-y="-20"
              font="bold 14px Arial" fill-color="black" stroke-color="white" stroke-width="2" />
          </ol-style>
        </ol-feature>
        <ol-interaction-modify @modifyend="onFeatureMoved" />
      </ol-source-vector>
    </ol-vector-layer>

  </ol-map>
</template>
<style lang="sass" scoped></style>

<script setup lang="ts">

import type { ContextMenuEvent, Item } from "ol-contextmenu";
import { type View } from "ol";
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useMissionDialog } from "@/composables/useNewMissionDialog";

import { fromLonLat, toLonLat } from 'ol/proj';

import markerIcon from "@/assets/location-pin.png";

const view = ref<View | null>(null);
const store = useStore();

const units = computed(() => store.getters["units/units"]);
const positions = computed(() => store.getters["positions/unitPositions"]);
const missions = computed(() => store.getters["missions/missions"]);
const newMissionDialog = useMissionDialog();

const allUnits = computed(() => store.getters["units/units"]);
const unitsWithPositions = computed(() => {
  return units.value
    .map(unit => ({
      ...unit,
      latestPosition: positions.value.find(pos => pos.unitId === unit.id) || null // Hakee position id:n perusteella
    }))
    .filter(unit => unit.latestPosition !== null);
});

const missionsWithPositions = computed(() => {
  return missions.value.filter(missions => missions.latitude !== null && missions.longitude !== null);
});


const contextMenuItems = computed(() => [
  {
    text: "Keskitä kartta tähän",
    classname: "some-style-class", // add some CSS rules
    callback: (val) => {
      view.value?.setCenter(val.coordinate);
    }, // `center` is your callback function
  },
  {
    text: "Sijoita yksikkö tähän",
    classname: "some-style-class", // you can add this icon with a CSS class
    // instead of `icon` property (see next line)
    //icon: marker, // this can be relative or absolute
    items: allUnits.value.map(unit => (
      {
        text: unit.name,
        callback: ({ coordinate }) => {
          const clickedLonLat = toLonLat(coordinate);
          store.dispatch("positions/updateUnitPosition", {
            unitId: unit.id,
            latitude: clickedLonLat[1],
            longitude: clickedLonLat[0],
            timestamp: new Date().toISOString()
          });
        }
      }
    )
    )
  },
  {
    text: "Uusi tehtävä tähän",
    classname: "some-style-class", // you can add this icon with a CSS class
    // instead of `icon` property (see next line)
    //icon: marker, // this can be relative or absolute
    callback: async ({ coordinate }) => {
      const clickedLonLat = toLonLat(coordinate);
      await store.commit("missions/newMissionToEditor");
      store.commit("missions/set", ["editor.latitude", clickedLonLat[1]])
      store.commit("missions/set", ["editor.longitude", clickedLonLat[0]])
      newMissionDialog.open();
    }
  },
]);

const center = ref(fromLonLat([26.940977, 60.464080]));
const projection = ref("EPSG:3857");

onMounted(async () => {
  store.dispatch("missions/load");
  store.dispatch("units/load");
  store.dispatch("positions/load");
});

function onFeatureMoved(event) {
  event.features.forEach((feature) => {
    const newCoords = toLonLat(feature.getGeometry().getCoordinates());
    const unitId = feature.getProperties().unitId;
    console.log("feature moved", newCoords);
    console.log("unitId", unitId);
    store.dispatch("positions/updateUnitPosition", {
      unitId: unitId,
      latitude: newCoords[1],
      longitude: newCoords[0],
      timestamp: new Date().toISOString()
    });
  });
}

function centerChanged(s) {
  //console.log(s);
}
</script>
