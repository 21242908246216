<template>
  <v-snackbar
    :model-value="
      $auth.state.isAuthenticated &&
        !isLoading &&
        !isLoggingOut &&
        signalRStatusToastData.visible
    "
    :color="signalRStatusToastData.color"
    multi-line
    location="bottom"
    :timeout="signalRStatusToastData.timeout"
  >
    <div class="ma-4 font-weight-bold">
      <v-row>
        <v-icon size="large" class="mr-4">mdi-alert</v-icon>
        {{ signalRStatusToastData.message }}
        <v-btn
          v-if="signalRStatusToastData?.details != null && !showDetails"
          text
          x-small
          @click="showDetailsHandler"
        >
          Show details
        </v-btn>
      </v-row>

      <v-row v-if="signalRStatusToastData?.details != null && showDetails">
        {{ signalRStatusToastData.details }}
      </v-row>
    </div>
  </v-snackbar>
</template>
<script>
export default {
  name: "SignalRAlert",
  data() {
    return {
      showDetails: false,
      close: true
    };
  },
  computed: {
    signalRStatus() {
      return this.$store.getters["app/signalRState"];
    },
    isLoading() {
      return this.$store.getters["app/isLoading"];
    },
    isLoggingOut() {
      return this.$store.getters["app/isLoggingOut"];
    },
    signalRStatusToastData() {
      switch (this.signalRStatus) {
        case "Disconnected":
          return {
            visible: true,
            color: "red",
            icon: "mdi-alert",
            message:
              "Yhteys palvelimeen on katkennut. Tieto voi olla vanhentunutta.",
            details: null,
            timeout: -1
          };
        case "Connecting":
          return {
            visible: false,
            color: "yellow",
            icon: "mdi-lan-pending",
            message: "Yhteyttä palvelimeen muodostetaan.",
            details: null,
            timeout: -1
          };
        case "Connected":
          return {
            visible: false,
            color: "green",
            icon: "mdi-lan-connect",
            message: "Yhteys palvelimeen on muodostettu.",
            details: null,
            timeout: -1
          };
        default:
          return {
            visible: true,
            color: "red",
            icon: "mdi-alert",
            message:
              "Palvelinyhteyden tila on epäselvä. Tieto voi olla vanhentunutta.",
            details: "Tilakoodi: " + this.signalRStatus,
            timeout: -1
          };
      }
    }
  },
  created() {},

  methods: {
    showDetailsHandler() {
      const me = this;
      me.showDetails = true;
    }
  }
};
</script>
